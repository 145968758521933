import { useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import { useLocation } from "react-router";
import {
	Box,
	Paper,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	SwipeableDrawer,
	IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// cmp
import Svg from "./svg";
// hooks
import useIsSmallScreen from "../hooks/useIsSmallScreen";
import useIsKeyboardOpen from "../hooks/useIsKeyboardOpen";
// services
import Constants from "../services/constants";
// types
import type { ReactNode } from "react";
import type { Theme, SxProps } from "@mui/material/styles";

const PULLER_HEIGHT = 6;

const Puller = styled(Box)(({ theme }) => ({
	margin: "8px auto 0",
	width: 30,
	height: PULLER_HEIGHT,
	borderRadius: PULLER_HEIGHT / 2,
	backgroundColor: theme.palette.text.primary,
}));

const ON_OPEN = () => {};

const ON_OPENING_DEFAULT = () => {};

const DIALOG_CONTENT_SX_DEFAULT = {
	textAlign: "center",
} as const;

const calculateMaxHeight = (id: string) => {
	const cardHeader = document.querySelector<HTMLElement>(`#${id} .MuiCardHeader-root`);
	const cardActionArea = document.querySelector<HTMLElement>(`#${id} .MuiCardActions-root`);
	return `calc(100dvh - 64px - ${cardHeader?.offsetHeight ?? 0}px - ${cardActionArea ? Math.max(64, cardActionArea.offsetHeight) : 0}px - env(safe-area-inset-top) - env(safe-area-inset-bottom))`;
};

type Props = Readonly<{
	id: string;
	title: ReactNode;
	open: boolean;
	initContentOnlyIfOpen?: boolean;
	fullSizeDrawer?: boolean;
	disableSwipe?: boolean;
	drawerActions: ReactNode;
	dialogActions: ReactNode;
	onClose: (event: object, reason?: "backdropClick" | "escapeKeyDown") => void;
	onOpening?: () => void;
	children: ReactNode;
	dialogContentSx?: SxProps<Theme>;
}>;

const DrawerDialog = ({
	id,
	title,
	open,
	initContentOnlyIfOpen = false,
	fullSizeDrawer = false,
	disableSwipe = false,
	children,
	drawerActions,
	dialogActions,
	onClose,
	onOpening = ON_OPENING_DEFAULT,
	dialogContentSx = DIALOG_CONTENT_SX_DEFAULT,
}: Props) => {
	const { pathname } = useLocation();
	const isSmallScreen = useIsSmallScreen();
	const isKeyboardOpen = useIsKeyboardOpen();

	useEffect(() => {
		if (open && isSmallScreen) {
			onOpening();
		}
	}, [isSmallScreen, open, onOpening]);

	if (isSmallScreen) {
		const cardHeader = (
			<>
				<Puller />
				<CardHeader
					title={title}
					slotProps={{ title: { align: "center", padding: "0 34px" } }}
					action={
						<IconButton size="small" aria-label="close" onClick={onClose} sx={{ position: "absolute", right: "16px" }}>
							<Svg src="actions/clear.svg" />
						</IconButton>
					}
					sx={{
						"position": "relative",
						"& .MuiCardHeader-action": {
							margin: 0,
						},
						...fullSizeDrawer ? {} : {
							paddingLeft: "calc(16px + env(safe-area-inset-left))",
							paddingRight: "calc(16px + env(safe-area-inset-right))",
						},
					}}
				/>
			</>
		);

		return (
			<SwipeableDrawer
				id={id}
				anchor="bottom"
				open={open}
				onOpen={ON_OPEN}
				onClose={onClose}
				disableSwipeToOpen={true}
				onTouchStart={disableSwipe ?
					(event) => {
						event.nativeEvent.defaultMuiPrevented = true;
					}
					: undefined
				}
				slotProps={{
					paper: {
						square: false,
						sx: {
							borderBottomLeftRadius: 0,
							borderBottomRightRadius: 0,
							[fullSizeDrawer ? "height" : "maxHeight"]: "calc(100dvh - 56px - env(safe-area-inset-top))",
						},
					},
				}}
				sx={{
					zIndex: pathname.startsWith("/preLogin/") ? "modal" : undefined,
				}}
			>
				<Card
					sx={{
						display: "grid",
						gridTemplateRows: "max-content 1fr max-content",
						height: "100%",
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
						paddingBottom: "env(safe-area-inset-bottom)",
					}}
				>
					{fullSizeDrawer
						? <Paper square={true} sx={{ zIndex: 0, paddingLeft: "env(safe-area-inset-left)", paddingRight: "env(safe-area-inset-right)" }}>{cardHeader}</Paper>
						: cardHeader
					}
					<CardContent
						sx={{
							display: "flex",
							flexDirection: "column",
							paddingLeft: "calc(16px + env(safe-area-inset-left))",
							paddingRight: "calc(16px + env(safe-area-inset-right))",
							overflow: "auto",
							maxHeight: (isKeyboardOpen && Capacitor.getPlatform() === Constants.Platform.iOS) ? "auto" : calculateMaxHeight(id),
							backgroundColor: fullSizeDrawer ? "background.default" : undefined,
							...dialogContentSx, // eslint-disable-line @typescript-eslint/no-misused-spread
						}}
					>
						{(open || !initContentOnlyIfOpen) && children}
					</CardContent>
					{drawerActions &&
						<CardActions
							sx={{
								justifyContent: "center",
								paddingLeft: "calc(8px + env(safe-area-inset-left))",
								paddingRight: "calc(8px + env(safe-area-inset-right))",
								backgroundColor: fullSizeDrawer ? "background.default" : undefined,
							}}
						>
							{drawerActions}
						</CardActions>
					}
				</Card>
			</SwipeableDrawer>
		);
	}

	return (
		<Dialog
			id={id}
			open={open}
			onClose={onClose}
			fullWidth={true}
			TransitionProps={{
				onEntering: onOpening,
			}}
		>
			<DialogTitle>
				{title}
			</DialogTitle>
			<DialogContent>
				{(open || !initContentOnlyIfOpen) && children}
			</DialogContent>
			<DialogActions>
				{dialogActions}
			</DialogActions>
		</Dialog>
	);
};

export default DrawerDialog;
